import React from 'react';
import './style.css'
const About = () => {
  return (
    <section className="section-padding" id="about_page">
      <div className="container">
        <div className="text-center">
          <div className="col-xs-12 col-md-10 col-md-offset-1">
            <div className="page-title mass">
              <div className="space-20"></div>
              <h5 className="title">ABOUT KHATA TRACKER</h5>
              <div className="space-30"></div>
              {/* <h3 className="blue-color">Khata Book Pocket Money Expanse Manager is Spending Tracker helps you to organise life easy</h3> */}
              {/* <div className="space-20"></div> */}
              <p>
              Khata Tracker makes managing your money easy. You can quickly record your 
daily expenses, sort them into categories, and set budgets to control your 
spending. The app gives you simple reports and charts to help you see where 
your money is going, so you can make better financial choices. Your data is safe 
and can be accessed from any device, giving you peace of mind. By keeping 
track of your spending, you can cut down on waste and save more for the future. 
Join the Khata Tracker community to take control of your finances, stay 
organized, and make the most of every rupee!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
