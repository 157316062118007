// import React from 'react';
// import Img from '../download imgs/KT_Logo_white.png';
// import '../components/nav.css';

// document.querySelectorAll('a[href^="#"]').forEach(anchor => {
//   anchor.addEventListener('click', function (e) {
//       e.preventDefault();
//       // Perform your scroll animation or adjustment here
//       // Example: scroll to the section with the id corresponding to href
//       const targetId = this.getAttribute('href').substring(1); // Remove #
//       const targetElement = document.getElementById(targetId);
//       // Scroll smoothly to the target element
//       if (targetElement) {
//           targetElement.scrollIntoView({
//               behavior: 'smooth'
//           });
//       }
//   });
// });
// history.replaceState(null, null, `#${targetId}`);
// const Navbar = () => {
//   return (
//     <nav className="mainmenu-area navbar navbar-expand-lg navbar-light" data-spy="affix" data-offset-top="200">
//       <div className="container-fluid d-flex justify-content-between">
//         <div className="d-flex align-items-center">
//           <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#primary_menu"
//      style={{ backgroundColor: "#fff" }}>
//             <span className="navbar-toggler-icon"></span>
//           </button>
//           <a className="navbar-brand ml-2" href="index">
//             <img src={Img} alt="Logo" className='nav_img'/>
//           </a>
//         </div>
//         <div className="collapse navbar-collapse justify-content-end" id="primary_menu" >
//           <ul className="navbar-nav ml-auto">
//             <li className="nav-item active">
//               <a className="nav-link" href="#home_page">Home</a>
//             </li>
//             <li className="nav-item">
//               <a className="nav-link" href="#about_page">About</a>
//             </li>
//             <li className="nav-item">
//               <a className="nav-link" href="#features_page">Features</a>
//             </li>
//             <li className="nav-item">
//               <a className="nav-link" href="#gallery_page">Gallery</a>
//             </li>
//             <li className="nav-item">
//               <a className="nav-link" href="#download">Download</a>
//             </li>
//             <li className="nav-item">
//               <a className="nav-link" href="#contact_page">Contact Us</a>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </nav>
//   );
// }

// export default Navbar;

import React, { useEffect } from 'react';
import Img from '../assets/images/KT_Logo_white.png';
import '../components/nav.css';
import img1 from '../assets/images/left-arrow.png';

const Navbar = () => {
  useEffect(() => {
    const anchors = document.querySelectorAll('a[href^="#"]');
    anchors.forEach(anchor => {
      const handleAnchorClick = function (e) {
        e.preventDefault();
        const targetId = this.getAttribute('href').substring(1); // Remove #
        const targetElement = document.getElementById(targetId);
        // Scroll smoothly to the target element
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: 'smooth'
          });
          window.history.replaceState(null, null, `#${targetId}`);
        }
      };

      anchor.addEventListener('click', handleAnchorClick);

      return () => {
        anchor.removeEventListener('click', handleAnchorClick);
      };
    });
  }, []);

  return (
    <nav className="mainmenu-area navbar navbar-expand-lg navbar-light" data-spy="affix" data-offset-top="200">
      <div className="container-fluid d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#primary_menu"
            style={{ backgroundColor: "#fff" }}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand ml-2" href="index">
            <img src={Img} alt="Logo" className='nav_img' />
          </a>
        </div>
        <div className="collapse navbar-collapse justify-content-end" id="primary_menu">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <a className="nav-link" href="#home_page">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about_page">About</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#features_page">Features</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#gallery_page">Gallery</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#download">Download</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact_page">Contact Us</a>
            </li>
            {/* <li>
              <button class="login-button" onClick={() => window.open('https://khatatracker.com/Application', '_blank')}>
                Login
              </button>
            </li> */}
          </ul>
          {/* <button className='nav-login text-center'>login<img src={img1} className='login-arrow'/></button> */}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

