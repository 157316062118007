import React from 'react';
import Slider from 'react-slick';
import image1 from '../assets/images/1.png';
import image2 from '../assets/images/2.png';
import image3 from '../assets/images/3.png';
import image4 from '../assets/images/4.png';
import image5 from '../assets/images/5.png';
import mobileImage from '../assets/images/frameTesting.png'; // Import the mobile image
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Gallery = () => {

  const settings = {
    infinite: true,
    speed: 500, 
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000, 
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 5,
    focusOnSelect: true,  
  };

  return (
    <div>
      <style jsx>{`
        .slick-prev, .slick-next {
          display: none !important; /* Hide the arrows */
        }
        .carousel-overlay {
          position: relative;
          width: 100%;
          max-width: 100%;
          margin: 0 auto;
        }
        .carousel-overlay .item {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 25px;
        }
        .carousel-overlay .item img {  
          height: auto;
          object-fit: cover;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }
        .carousel-overlay .slick-center .item img {
          transform: scale(1.11); 
         
        } 
          

        .carousel-overlay .slick-slide:nth-child(3).slick-active .item img {
          filter: brightness(1.2) contrast(1.2) saturate(1.2); 
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5), inset 0 0 5px rgba(255, 255, 255, 0.5);
        }
         
        .page-title {
          margin-top: 20px;
        }
        .bttn-default {
          display: inline-block;
          padding: 10px 20px;
          background-color: #007bff;
          color: white;
          text-decoration: none;
          border-radius: 5px;
          margin-bottom: 10%;
        }
        .bttn-default:hover {
          background-color: #0056b3;
        }
        .white-color {
          color: white;
        }
      `}</style>
      <section className="gallery-area section-padding" id="gallery_page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-lg-12">

            <div className="page-title text-center" >
  <h5 className="title" style={{color:'#fff'}}>Feature Images</h5>
  <div className="space-10"></div>
  <h3>Some of Screenshots in the App</h3>
  <div className="space-60"></div>
</div>

               
              <div className="carousel-overlay">
                {/* Mobile image as backdrop */} 
                <Slider {...settings} className="slider">
                  <div className="item"><img src={image1} alt="Gallery Image 1" /></div>
                  <div className="item"><img src={image2} alt="Gallery Image 2" /></div>
                  <div className="item"><img src={image3} alt="Gallery Image 3" /></div>
                  <div className="item"><img src={image4} alt="Gallery Image 4" /></div>
                  <div className="item"><img src={image5} alt="Gallery Image 5" /></div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Gallery;
