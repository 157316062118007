import React, { useState } from 'react';
import logo from '../assets/images/KT_Logo.png';
import '../components/delete.css'; // Import CSS module
import { FaEye, FaEyeSlash } from "react-icons/fa";

const DeleteAccount = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();
    const data = { email, password };

    try {
      const response = await fetch('http://98.130.20.93:3001/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Invalid username or password');
      }

      const result = await response.json();
      const isConfirmed = window.confirm('By confirming you will remove your account and backups data from cloud!');

      if (isConfirmed) {
        deleteAccount(email);
      } else {
        alert('Thank you for keeping your account and data.');
      }
    } catch (error) {
      alert('Error: ' + error.message);
    }
  };

  const deleteAccount = async (email) => {
    try {
      const response = await fetch(`http://98.130.20.93:3001/deleteaccount/${encodeURIComponent(email)}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      alert(result.message);
      window.location.href = 'https://www.khatatracker.com/';
    } catch (error) {
      alert('Error: ' + error.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="login-container">
        <div className="login-box">
          <div className="login-title">
            <img src={logo} className="login-logo" alt="Logo" />
          </div>
          <form className="login-form" autoComplete="off" onSubmit={submitForm}>
            <input type="text" style={{ display: 'none' }} /> {/* Hidden field to disable auto-complete */}
            <div className="form-group">
              <label htmlFor="email" className="label-over"><span className="text-view">Email </span></label><br/>
              <input
                className="form-controls"
                id="email"
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="off"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password" className="label-over"><span className="text-view">Password</span></label>
              <div className="password-wrapper">
                <input
                  className="form-controls"
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  autoComplete="off"
                />
                <div className="eye-icon" onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-login btn-block">
              Login
            </button>
            <div className="login-footer"></div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DeleteAccount;
