import React, { useState } from 'react';
import './style.css';

const FAQ = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleCollapse = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div>
      <section id="questions_page" className="questions-area section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12">
              <div className="page-title text-center">
                <h5 className="title">FAQ</h5>
                <h3 className="dark-color">Frequently Asked Questions</h3>
                <div className="space-60"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="toggole-boxs">
                {[

{ question: "Is Khata Tracker available on both Android and iOS?", answer: "Yes, Khata Tracker is available for download on both Android and iOS platforms." },
  { question: "Can I use Khata Tracker offline?", answer: "Yes, you can record transactions offline, and the app will sync your data once you are connected to the internet." },
  { question: "How do I back up my data in Khata Tracker?", answer: "Khata Tracker automatically backs up your data to the cloud, ensuring it's safe and accessible across devices." },
  { question: "Can I customize the categories for my expenses?", answer: "Yes, you can easily create and customize categories to suit your specific needs." },
  { question: "Is my data secure in Khata Tracker?", answer: "Yes, your data is protected with secure login methods like passcode and fingerprint authentication." },
  { question: "Can I export my transaction data?", answer: "Yes, you can export your transaction history and reports in PDF format for easy record keeping." },
  { question: "How do I reset my Khata Tracker password?", answer: "You can reset your password by going to the 'Forgot Password' option on the login screen and following the instructions." },
  { question: "Does Khata Tracker support multiple currencies?", answer: "Yes, Khata Tracker allows you to manage transactions in multiple currencies based on your preferences." },
  { question: "Can I set reminders for upcoming payments or dues?", answer: "Yes, Khata Tracker allows you to set reminders for bills or payments to help you stay on track." },
  { question: "Is there a limit to the number of ledgers I can create?", answer: "No, you can create unlimited ledgers to manage different accounts or businesses." },
  { question: "Can Khata Tracker be used multiple users?", answer: "Yes, Khata Tracker can be used by multiple users, but each user needs their own account to keep their data separate and secure." },
  { question: "Does Khata Tracker share data with government?", answer: "No, Khata Tracker does not share your data with the government. Your information remains private and secure." }
                 ].map((item, index) => (
                  <div key={index} className="faq-item">
                    <h3
                      className={expandedIndex === index ? 'expanded' : ''}
                      onClick={() => toggleCollapse(index)}
                    >
                      {item.question}
                    </h3>
                    {expandedIndex === index && (
                      <div style={{borderLeft: 'none'}}>
                        <p>{item.answer}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FAQ;
