// // import React from 'react';
// // import headermobile from '../assets/images/header-mobile2.png';
// // import   playstore from '../assets/images/PlayStoreBadge.png';
// // import  google from '../assets/images/GooglePlayStore.png';

// // const Home = () => {
// //   return (
// //     <header className="home-area overlay" id="home_page">
// //       <div className="container">
// //         <div className="row">
// //           <div className="col-xs-12 hidden-sm col-md-5">
// //             <figure className="mobile-image wow fadeInUp" data-wow-delay="0.2s">
// //               <img src={headermobile} alt="" />
// //             </figure>
// //           </div>
// //           <div className="col-xs-12 col-md-7">
// //             <div className="space-80 hidden-xs"></div>
// //             <h2 className="wow fadeInUp" data-wow-delay="0.4s">Khata Book My Spending Tracker</h2>
// //             <div className="space-20"></div>
// //             <div className="desc wow fadeInUp" data-wow-delay="0.6s">
// //               <ul>
// //                 <li>Are you worried about your expenses?</li>
// //                 <li>Are you worried about your income?</li>
// //                 <li>Are you worried about daily bills?</li>
// //               </ul>
// //             </div>
// //             <div className="space-20"></div>
// //             <figure className="wow fadeInUp" data-wow-delay="0.8s">
// //               <a href="https://apps.apple.com/in/app/khata-book-my-spending-tracker/id1517793704" target="_blank">
// //                 <img src={playstore} alt="Download on the App Store" />
// //               </a>
// //               <a href="https://play.google.com/store/apps/details?id=personal.expense.budget.pocket.moneytracker" target="_blank">
// //                 <img src={google} alt="Get it on Google Play" />
// //               </a>
// //             </figure>
// //           </div>
// //         </div>
// //       </div>
// //     </header>
// //   );
// // };

// // export default Home;
// import React from 'react';
// import headermobile from '../assets/images/header-mobile2.png';
// import playstore from '../assets/images/PlayStoreBadge.png';
// import google from '../assets/images/GooglePlayStore.png';
// import './style.css';


// const Home = () => {
//   return (
//     <header className="home-area overlay" id="home_page">
//       <div className="container">
//         <div className="row">
//           <div className="col-xs-12 hidden-sm col-md-5">
//             <figure className="mobile-image wow fadeInUp" data-wow-delay="0.2s">
//               <img src={headermobile} alt="" />
//             </figure>
//           </div>
//           <div className="col-xs-12 col-md-7">
//             <div className="space-80 hidden-xs"></div>
//             <h2 className="wow fadeInUp"  style={{fontStyle:"Times new roman"}} data-wow-delay="0.4s"><strong>Khata Tracker</strong></h2>
//             <div className="space-20"></div>
//             <div className="desc wow fadeInUp" data-wow-delay="0.6s"> 
//               <p>
//               Khata Tracker helps you easily organize your finances. It's a simple app for 
// managing cash, perfect for any type of business. With Khata Tracker, you can 
// track daily income, expenses, sales, purchases, and all your credit and debit 
// entries. Managing your day-to-day transactions is quick and effortless.
//               </p>
//             <br></br>
//             </div>
//             <div className="space-20"></div>
//             <figure className="wow fadeInUp" data-wow-delay="0.8s">
//               <a href="https://apps.apple.com/in/app/khata-book-my-spending-tracker/id1517793704" target="_blank" rel="noopener noreferrer">
//                 <img src={playstore} alt="Download on the App Store" />
//               </a>
//               <a href="https://play.google.com/store/apps/details?id=personal.expense.budget.pocket.moneytracker" target="_blank" rel="noopener noreferrer">
//                 <img src={google} alt="Get it on Google Play" />
//               </a>
//             </figure>
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// };

// export default Home;
import React from 'react';
import './home.css'; // Add your custom styles here
import img from '../assets/images/destop.png';
import playstore from '../assets/images/PlayStoreBadge.png';
import google from '../assets/images/GooglePlayStore.png';
const Home = () => {
  return (
    <section className="khata-tracker container-fluid ">
      <div className="row align-items-center justify-content-between">
        {/* Left Section: Main text and buttons */}
        <div className="col-lg-5 col-md-6 col-sm-12 text-section container">
          <h1 className="display-4 fw-bold " style={{fontFamily:"Nunito"}}>
            Simplify Your<br/> Business Accounts  <br/>with<span style={{color:"#99FFEA"}}> Khata Tracker!</span>
          </h1>
          <p className="lead">
            Khata Tracker helps you manage your finances. It's a simple app for managing cash, perfect for any type of business. 
            With Khata Tracker, you can track daily income, expenses, sales, purchases, and all your credit and debit entries. 
            Managing your day-to-day transactions is quick and effortless.
          </p>
          <div className="buttons my-4">
            <button className="trail mb-2">Start 7 Day Free Trial</button>
            <button className="trails">Register Now</button>
          </div>
          <p>Paid plans starting from <span style={{color:'#2DF9D0',textDecoration:'underline'}}>Rs. 699/month</span></p>
          <div className="play-store">
            <div className="app-store ">
            <p style={{color:"#fff"}}>Also available here </p>
           <a href="https://apps.apple.com/in/app/khata-book-spending-tracker/id1517793704" target='_blank'> <img src={playstore} alt="App Store" className="me-2 "  /></a>
          <a href='https://play.google.com/store/apps/details?id=personal.expense.budget.pocket.moneytracker&pcampaignid=web_share'target='_blank' > <img src={google} alt="Play Store" /></a>
            </div>
          </div>
        </div>

        {/* Right Section: Device images */}
        <div className="col-lg-6 col-md-6 col-sm-12 image-section">
          <div className="device-images d-flex justify-content-center">
            <img src={img} alt="Desktop View" className="img-fluid desktop-img" />
          
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
