import React from 'react';
import './style.css';
import process from '../assets/images/progress-phone (1).png'
const OurProcess = () => {
  return (
    <div>
      <section className="progress-area gray-bg" id="progress_page">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className="page-title section-padding">
                <h5 className="title wow fadeInUp" data-wow-delay="0.2s"><b>Why Use the Khata Tracker App?</b></h5>
                {/* <div className="space-10"></div>
                <h3 className="dark-color wow fadeInUp" data-wow-delay="0.4s">It's a Good Application</h3> */}
                <div className="space-20"></div>
                <div className="desc wow fadeInUp" data-wow-delay="0.6s">
                  <ul>
                    <li><b>Easy to Use : </b>Simple and clear design for smooth navigation and quick 
                    data entry.</li>
                    <li><b>Multiple Accounts : </b>Create separate ledgers for your business and 
                    personal finances.</li>
                    <li><b>Live Balance Updates : </b>See your current balance and full transaction 
                    history for each account instantly.</li>
                    <li><b>Customizable : </b>Rename ledgers and categories to fit your specific needs.</li>
                    <li><b>Export Data : </b>Save your records as PDF files for easy tracking and 
                    bookkeeping.</li>
                    <li><b>Automatic Backup : </b>Your data is safely stored and synced across all your 
                    devices.</li>
                    <li><b>Secure Login : </b>Protect your information with a passcode or fingerprint 
                      authentication.</li>
                    <li><b>Add Notes & Images : </b>Attach notes or pictures to transactions for 
                    detailed record keeping.</li>
                  </ul> 
                </div>
                <div className="space-50"></div>
                {/* <a href="#" className="bttn-default wow fadeInUp" data-wow-delay="0.8s">Learn More</a> */}
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <figure className="mobile-image">
                <img src={process} alt="Progress Phone"/>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OurProcess;
