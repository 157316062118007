import React from 'react';

const CancellationRefundPolicy = () => {
  return (
    <div>
      <h2>Cancellation & Refund Policy</h2>
      <h3>Last updated on 04-03-2024 22:21:32</h3>
      <p>ANJU SIIMA TECHNOLOGIES PRIVATE LIMITED believes in helping its customers as far as 
        possible, and has therefore a liberal cancellation policy. Under this policy:</p>
      <ul>
        <li>Cancellations will be considered only if the request is made immediately after placing the order. 
        However, the cancellation request may not be entertained if the orders have been communicated to the 
        vendors/merchants and they have initiated the process of shipping them.</li>
        <li>ANJU SIIMA TECHNOLOGIES PRIVATE LIMITED does not accept cancellation requests for 
        perishable items like flowers, eatables etc. However, refund/replacement can be made if the customer 
        establishes that the quality of product delivered is not good.</li>
        <li>In case of receipt of damaged or defective items please report the same to our Customer Service team. 
        The request will, however, be entertained once the merchant has checked and determined the same at his 
        own end. This should be reported within Only same day days of receipt of the products. In case you feel 
        that the product received is not as shown on the site or as per your expectations, you must bring it to the 
        notice of our customer service within Only same day days of receiving the product. The Customer 
        Service Team after looking into your complaint will take an appropriate decision.</li>
        <li>In case of complaints regarding products that come with a warranty from manufacturers, please refer 
        the issue to them. In case of any Refunds approved by the ANJU SIIMA TECHNOLOGIES PRIVATE 
        LIMITED, it’ll take 1-2 Days days for the refund to be processed to the end customer.</li>
      </ul>
    </div>
  );
};

export default CancellationRefundPolicy;
